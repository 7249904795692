import Vue from 'vue'
import VueInstance from '../main.js'

function lookupPermission(route) {
	try {
		const {
			parent,
			meta
		} = route
		const {
			permission
		} = meta
		const foundPermission = permission || lookupPermission(parent)
		if (foundPermission) {
			return foundPermission
		}
	} catch (e) {
		console.error(e)
	}
}
function canAccess(user, path) {
	const {
		permissions = [], superAdmin = false
	} = user
	if (superAdmin) return true
	const {
		matched,
		meta: {
			permission
		},
		fullPath
	} = VueInstance.$router.match(path)
	var uncheckedPermission = permission || lookupPermission(matched[matched.length - 1])
	if (permissions && uncheckedPermission && permissions.find(p => p === uncheckedPermission) !== undefined) {
		return true
	}
	return false
}

Vue.directive('auth', {
	bind: function(el, binding, vnode) {
		const {
			to: {
				path
			}
		} = binding.value
		try {
			const hasPermission = canAccess(VueInstance.$store.state.user.user, path)
			if (hasPermission) {
				return
			}
			el.style.display = 'none'
		} catch (e) {
			console.info(e)
		}
	}
})
