export default {
	title: '广告营销系统',
	navBar: {
		list: [{
			icon: 'el-icon-document',
			name: "广告数据",
			submenu: [{
				name: 'JSCX报表',
				pathname: 'jscx'
			}, {
				name: 'JSCX关系维护',
				pathname: 'jscx-relation'
			}]
		}, {
			icon: 'el-icon-setting',
			name: '设置',
			submenu: [{
				name: '账号管理',
				pathname: 'settings_account'
			}, {
				name: '账户权限管理',
				pathname: 'settings_token'
			}]
		}]
	}
}

export const getDefaultNavBar = (navBar) => {
	return {list: [...navBar.list]}
};
