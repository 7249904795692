import Vue from 'vue'

// 引入全局组件
import mainContainer from '@/components/common/main-container.vue'
Vue.component('MainContainer', mainContainer)

import FormItem from '@/components/common/form-item.vue'
Vue.component('FormItem', FormItem)

import ImgRadio from '@/components/common/img-radio.vue'
Vue.component('ImgRadio', ImgRadio)

import MainTabs from '@/components/common/main-tabs.vue'
Vue.component('MainTabs', MainTabs)

import UploadButton from '@/components/upload-button/index.vue'
Vue.component('UploadButton', UploadButton)

import Alert from '@/components/alert/index.vue'
Vue.component('Alert', Alert)

import TextTruncate from '@/components/common/text-truncate.vue'
Vue.component('TextTruncate', TextTruncate)

import ElFormItemSites from '@/components/form-items/el-form-item-sites.vue'
Vue.component('ElFormItemSites', ElFormItemSites)

import ElFormItemCountries from '@/components/form-items/el-form-item-countries.vue'
Vue.component('ElFormItemCountries', ElFormItemCountries)

import FormItemCategories from '@/components/form-items/form-item-categories.vue'
Vue.component('FormItemCategories', FormItemCategories)

import FormItemCountries from '@/components/form-items/form-item-countries.vue'
Vue.component('FormItemCountries', FormItemCountries)

import FormItemPublishtypes from '@/components/form-items/form-item-publishtypes.vue'
Vue.component('FormItemPublishtypes', FormItemPublishtypes)

import FormItemSites from '@/components/form-items/form-item-sites.vue'
Vue.component('FormItemSites', FormItemSites)

import FormItemTemplates from '@/components/form-items/form-item-templates.vue'
Vue.component('FormItemTemplates', FormItemTemplates)

import UploadImgButton from '@/components/common/upload-img-button.vue'
Vue.component('UploadImgButton', UploadImgButton)

import UpdateImgButton from '@/components/common/update-img-button.vue'
Vue.component('UpdateImgButton', UpdateImgButton)

import FormItemChannels from '@/components/form-items/form-item-channels.vue'
Vue.component('FormItemChannels', FormItemChannels)

import FormItemLanguages from '@/components/form-items/form-item-languages.vue'
Vue.component('FormItemLanguages', FormItemLanguages)

import AdvancedSearchBar from '@/components/common/advanced-search-bar.vue'
Vue.component('AdvancedSearchBar', AdvancedSearchBar)

import Divider from '@/components/common/divider.vue'
Vue.component('Divider', Divider)
