import Vue from 'vue'
import {Message} from 'element-ui'
// 引入全局配置文件
import $conf from './config/config.js'
Vue.prototype.$conf = $conf

Vue.prototype.$showErrMsg = (errMsg) => {
	if (typeof errMsg !== 'string') return
	Message.error(errMsg)
}
Vue.prototype.$showSuccess = (msg) => {
	if (typeof msg !== 'string') return
	Message.success(msg)
}
Vue.prototype.$showWarning = (msg) => {
	if (typeof msg !== 'string') return
	Message.warning(msg)
}