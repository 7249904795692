export default {
	methods: {
		/**
		 * Vue Router
		 * */
		nav_push(name, params, query) {
			this.$router.push({
				name,
				params,
				query
			})
		},
		nav_replace(name, params, query) {
			this.$router.replace({
				name,
				params,
				query
			})
		},
		navigate(name) {

		},
		/**
		 * Element UI
		 * */
		handleClose(done) {
			this.$confirm('请确认是否要关闭?')
				.then(_ => {
					try {
						done(this.$emit('update:visible', false));
					} catch (err) {
						console.log(err)
					}
				})
				.catch(_ => {});
		},
		exportFileByApi(api, filename = 'undefined') {
			if (typeof api === 'object') {
				console.log(api, filename)
				this.$showWarning('正在为您准备数据...')
				api
				.then(res => {
					const { data, status, headers } = res
					const content_disposition = headers['content-disposition']
					const ext = content_disposition.match(/(\.[\w]+)/g) || ['.zip']
					if (status === 200 && typeof data === 'object') {
						this.exportFile(data, `${filename}${ext[0]}`)
					}
				})
				.catch(err => {
					this.$showErrMsg(err)
				})
			}
		},
		// Export File
		exportExcel(api, query, filename = 'undefined') {
			if (typeof api === 'function' && typeof query === 'object' && (Array.isArray(query) === false && query !== null)) {
				this.$showWarning('正在为您准备数据...')
				api({...query})
				.then(res => {
					const { data, status, headers } = res
					const content_disposition = headers['content-disposition']
					const ext = content_disposition.match(/(\.[\w]+)/g) || ['.zip']
					if (status === 200 && typeof data === 'object') {
						this.exportFile(data, `${filename}${ext[0]}`)
					}
				})
				.catch(err => {
					this.$showErrMsg(err)
				})
			}
		},
		exportFile(data, filename = 'untitled') {
			const blob = (window.URL || window.webkitURL).createObjectURL(new Blob([data]))
			const elt = document.createElement('a')
			elt.setAttribute('href', blob)
			elt.setAttribute('download', filename)
			document.body.appendChild(elt)
			elt.click()
			document.body.removeChild(elt)
		},
		downloadFile(url, filename = undefined) {
			let name = filename || url.split('/').pop()
			const elt = document.createElement('a');
			elt.setAttribute('href', url);
			elt.setAttribute('download', name);
			elt.style.display = 'none';
			document.body.appendChild(elt);
			elt.click();
			document.body.removeChild(elt);
		},
		// Validate Input
		isEmpty(obj) {
			if (typeof obj === 'object') {
				const v = JSON.stringify(obj)
				return v === '[]' || v === '{}' || v === 'null'
			} else if (typeof obj === 'string') {
				return obj === ''
			}
			return true
		}
	}
}
