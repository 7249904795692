import Vue from 'vue'
import Moment from 'moment'

Vue.filter('formatDate', (value, dateFormat, isSecond = true) => {
	if (typeof value === 'string' || typeof value === 'number') {
		return Moment(Number(value) * (isSecond ? 1 : 1000)).format(dateFormat || 'YYYY-MM-DD HH:mm:ss')
	}
	return null
})

Vue.filter('numToString', (val) => val.toString())

Vue.filter('capitalizeWord', (val) => {
	try {
		return val.charAt(0).toUpperCase() + val.slice(1)
	} catch {
		return val
	}
})
Vue.filter('formattedPrice', (val) => {
	const v = Number(val) / Number(100)
	return isNaN(v) ? '0' : `${v}`
})
Vue.filter('accumulator', (val) => {
	if (Array.isArray(val)) {
		return `${val.reduce((lhs, rhs) => lhs + Number(rhs), 0)}`
	}
	return '0'
})
Vue.filter('plus', (val, num) => {
	const v = Number(val) + Number(num)
	return isNaN(v) ? '0' : `${v}`
})
Vue.filter('mul', (val, num) => {
	const v = Number(val) * Number(num)
	return isNaN(v) ? '0' : `${v}`
})
Vue.filter('minus', (val, num) => {
	const v = Number(val) - Number(num)
	return isNaN(v) ? '0' : `${v}`
})
Vue.filter('nonnegative', (val) => {
	const v = Number(val) 
	if (isNaN(v)) {
		return '0'
	}
	return v < 0 ? '0' : val
})
Vue.filter('value', (key, list) => {
	const item = list.find(v => {
		if (typeof v === 'string') {
			return v === key
		}
		return v.key === key
	})
	return item && item.value || ''
})
Vue.filter('findLabelByKey', (item, list, key, label) => {
	try {
		return list.find(v => item === v[key])[label]
	} catch (e) {
		console.error(e)
		return ''
	}
})